import Icon from "@components/Icon";
import {IconName} from "@services/types";
import React, {HTMLAttributes, useId, useRef} from "react";
import {useDisclosure} from "src/hooks/useDisclosure";

import ErrorMessage from "../components/ErrorMessage";
import Label from "../components/Label";
import {inputStyles} from "../utils/commonStyles";

type Props = {
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  onChange: (input: string) => void;
  // onEnter gets called when the user hits the "enter" key.
  // The current text is passed as an argument.
  onEnter?: (input?: string) => unknown;
  onBlur?: () => void;
  icon?: IconName;
  label?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  errorMsg?: string;
  setValidation?: (validate: boolean) => string | void;
  autoComplete?: "on" | "off";
  /**
   * The value of the input. Use this if you need a controlled input.
   * Use case example: You are formatting or masking user input
   */
  value?: string;
  "data-cy"?: string;
  inputMode: HTMLAttributes<HTMLInputElement>["inputMode"];
};

const TextInput: React.FC<Props> = ({
  className,
  placeholder,
  onChange,
  onEnter,
  onBlur,
  icon,
  label,
  setValidation,
  errorMsg,
  ariaLabel,
  defaultValue,
  autoFocus = false,
  autoComplete = "off",
  value,
  "data-cy": dataCy,
  inputMode,
}) => {
  const focusState = useDisclosure();
  const ref = useRef<HTMLInputElement>(null);
  const isLabelSmall = focusState.isOpen || Boolean(value || ref.current?.value);
  const isBadInput = typeof errorMsg === "string";

  const inputId = useId();
  const labelId = useId();
  const errorMessageId = useId();

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    focusState.close();
    setValidation?.(true);
    onBlur?.();
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.value);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === "Enter") {
      if (!errorMsg) {
        onEnter?.(e.currentTarget.value);
      }
      setValidation?.(true);
    }
  };

  const opacity =
    isLabelSmall && placeholder ? "opacity-1" : !label || !placeholder ? "opacity-1" : "opacity-0";

  return (
    <div className="pos-r w-full">
      <div
        data-cy=""
        onClick={() => ref.current?.focus()}
        className={`${inputStyles.wrapper} ${borderStyles(focusState.isOpen, isBadInput)} ${
          className || ""
        } ${label ? "pt7" : ""} ${inputBgColor(focusState.isOpen, isBadInput)} cursor-text`}
      >
        {icon && (
          <Icon
            icon={icon}
            className={`pr2 fs26 fs22-sm -ml-2 mr1 fs28-f ${iconColor(focusState.isOpen)}`}
          />
        )}
        <Label label={label} id={labelId} htmlFor={inputId} isLabelSmall={isLabelSmall} />
        <input
          onChange={handleChange}
          onFocus={focusState.open}
          onBlur={handleOnBlur}
          value={value}
          id={inputId}
          ref={ref}
          aria-label={ariaLabel}
          aria-labelledby={labelId}
          className={`${inputStyles.input} ${label && "top-1.5"} ${opacity}`}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          defaultValue={defaultValue}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          data-cy={dataCy}
          autoComplete={autoComplete}
          aria-errormessage={isBadInput ? errorMessageId : undefined}
          aria-invalid={isBadInput}
          inputMode={inputMode}
        />
      </div>
      <ErrorMessage id={errorMessageId} msg={errorMsg} />
    </div>
  );
};

const borderStyles = (focused: boolean, error: boolean) =>
  error
    ? `border-2 border-solid border-error-700`
    : focused
    ? "border-blue-500 border-2 border-solid"
    : `border-2 border-solid border-gray-100`;

const iconColor = (focused: boolean): string => (focused ? "text-blue-500" : "text-gray400");

const inputBgColor = (focused: boolean, error: boolean) =>
  error ? "bg-white" : focused ? "bg-blue-100" : "bg-gray-100";

export default React.memo(TextInput);
