import {useEffect, useState} from "react";

import {addListenerSafe, removeListenerSafe} from "../utils/mediaQueries";
import {noOp} from "../utils/noOp";
import {Breakpoint} from "src/constants/breakpoint";

/**
 * Mobile first breakpoint listener matching tailwind default breakpoints
 *
 * Note: Use Tailwind responsive design instead if possible. Only use this
 * if the actual contents need to change rather than just styling. Even in those
 * cases, prefer using CSS visibility and keeping everything in Tailwind.
 * See: https://tailwindcss.com/docs/responsive-design
 */
export const useTwMediaQuery = (bp: Breakpoint): boolean => {
  const dimension = "width";
  const queryString = `(min-${dimension}: ${bp}px)`;

  const [matches, setMatches] = useState(false);
  useEffect(() => {
    if (typeof matchMedia !== "undefined") {
      const mediaQueryList = matchMedia(queryString);
      setMatches(mediaQueryList.matches);
      const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
      addListenerSafe(mediaQueryList, listener);
      return () => {
        removeListenerSafe(mediaQueryList, listener);
      };
    }
    return noOp;
  }, [queryString]);

  return matches;
};
