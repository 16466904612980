import {ApptSlotsWorkerParams} from "../../../workers/common/types";
import {ApptSlot} from "@services/monolith/availability";
import {tryFetchSlots} from "@services/cloudflare/slots";
import memoizee from "memoizee";
import {MsMap} from "../../constants/MsMap";

// Fetch slots and filter out any that are in the past.
//
// This is done because the cloudflare cache key relies on query params. Since we generally query
// for "slots between now() and {future date}", using the _actual_ bounds would have a very
// high cache miss rate. Instead, we typically query for "slots between {start of today} and {future date}."
// This produces many more cache hits, which improves performance for most users, but also means we
// need to filter out the slots between "{start of today" and now()" on the client.
const fetchFutureSlots = (args: ApptSlotsWorkerParams) =>
  tryFetchSlots(args).then(slots => slots?.filter(s => s?.time > Date.now()) || []);

export const fetchCachedSlots = memoizee(fetchFutureSlots, {
  maxAge: MsMap.ONE_MINUTE * 5, // memoize for 5 mins
  promise: true, // prevents caching bad response (exceptions)
  normalizer: JSON.stringify,
});

export const fetchCachedSlot = (args: ApptSlotsWorkerParams): Promise<ApptSlot | null> =>
  fetchCachedSlots(args).then(slots => slots?.[0] || null);
