/**
 * Given a fetch-like function, return a new one which parses the response body as JSON.
 */
export function jsonBody<T>(
  f: (path: string, init?: RequestInit) => Promise<Response>,
): (path: string, init?: RequestInit) => Promise<T> {
  return async (input: string, init?: RequestInit): Promise<T> => {
    const response = await f(input, init);
    return response.json();
  };
}
