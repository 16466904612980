import {createInstance, i18n} from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import {initReactI18next} from "react-i18next/initReactI18next";
import {I18nNamespace} from "./constants";
import {fixInterpolation} from "./fixInterpolation";
import {DEFAULT_I18N_OPTIONS} from "./options";

// This block initializes i18n for the server side.
// Loosely based upon the following guide, section 3 (i18n instrumentation):
// https://locize.com/blog/next-13-app-dir-i18n/
const initI18next = async (lng: string, ns: I18nNamespace): Promise<i18n> => {
  const i18nInstance = createInstance();
  await i18nInstance
    .use(initReactI18next) // initialize React integration
    .use(
      resourcesToBackend(
        // load our JSON translations into i18next
        (language: string, namespace: string) =>
          import(`../../../locales/${language}/${namespace}.json`),
      ),
    )
    .init({
      ...DEFAULT_I18N_OPTIONS,
      lng,
      ns,
    });
  return i18nInstance;
};

const createServerTranslation = async (lang: string, namespace?: I18nNamespace) => {
  const i18n = await initI18next(lang, namespace ?? "website");

  return {
    t: fixInterpolation(i18n.t),
    i18n,
  };
};

export {createServerTranslation};
